<template>
  <div class="envelope">
    <div class="describe">
      <div class="describe-title">活动简介</div>
      <div class="describe-cont">
        商家可自行更换评分歌曲完唱时的广告位素材，可在节假日和活动日，展示出商家的活动、宣传、公告等内容。
        <br>
        支持视频、图片两种格式
        <div class="cha" @click="chaClick">查看示例</div>
      </div>
    </div>
    <div class="title">
      <div>广告投放</div>
      <el-button type="primary" @click="titleaddClick()">新增投放</el-button>
    </div>
    <div class="from">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
         <el-form-item label="品牌" v-if="user_id === 0">
          <el-select v-model="formInline.ping" placeholder="请选择" @change="pingClick">
            <el-option v-for="(item,index) in pinglist" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店">
          <el-select v-model="formInline.company_id" placeholder="请选择" @change="menClick">
            <el-option v-for="(item,index) in menData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放状态"> 
          <el-select v-model="formInline.zhuang" placeholder="请选择" @change="zhuangClick">
            <el-option v-for="(item,index) in zhangData" :key="index" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="user_id == 0">
           <el-button type="primary" style="width:80px;margin-left: 15px;" @click="queClick">清空</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-cont">
       <el-table
        :data="tableData"
        stripe
        :header-cell-style="{'background-color':'#f5f5f5'}"
        style="width: 100%">
         <el-table-column
          prop="index"
          label="序号"
          width="80">
        </el-table-column>
         <el-table-column
          :show-overflow-tooltip="true"
          prop="store_name"
          label="门店名称">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="store_activity_name"
          label="活动名称">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="begin_date"
          label="活动开始时间">
        </el-table-column>
         <el-table-column
          prop="end_date"
          :show-overflow-tooltip="true"
          label="活动结束时间">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="company_name"
          label="活动状态">
          <template slot-scope="scope">
             {{  scope.row.status == 0 ? '禁用' : '启用' }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="id"
          width="60px">
          <template slot-scope="scope">
              <div class="tableColumn-control">
                  <i
                    v-if="!scope.row.showBtn"
                    @mouseenter="handleMouseEnter(scope.row)"
                    class="el-icon-more"
                  ></i>
                  <div v-else @mouseleave="handleMouseLeave(scope.row)">
                    <span @click="delTeacher(scope.row)">{{  scope.row.status == 0 ? '开启' : '关闭' }}</span>
                    <span @click="editClick(scope.row)">查看</span>
                  </div>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageStype">
        <el-pagination
          background
          @current-change="sizeChange"
          layout="prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
     <!-- 视频弹出层 -->
    <div class="vCstyle" v-if="voShow">
        <div>
            <video loop  autoplay=autoplay src="https://yc-zzyy.obs.cn-south-1.myhuaweicloud.com/om/canghai/ad_score_example.mp4">
                您的浏览器不支持Video标签。
            </video>
            <div @click="guan">关闭<i  class="el-icon-circle-close"></i></div>
        </div>
    </div>
  </div>
</template>
<script>
// addRedRainPlan
import {getPremiseList, editStatus, brandList, storeList} from '../../api/index'
export default {
  data () {
    return {
      voShow:false,
      user_id:null,
      type:'',
      manDta: JSON.parse(localStorage.getItem('company_list')),
      // 分页
      page:1,
      total:0,
      tableData:[],
      pinglist:[],
      menData:[],
       // 查询
      formInline:{
        ping:'',
        company_id:'',
        time:'',
        wei:'',
        zhuang:1
      }, 
       zhangData:[
        {
          type:0,
          name:'停用'
        },
         {
          type:1,
          name:'启用'
        }
      ],
      ping:'',
      zhuangType:[
        {
          id:0,
          name:'内部互动'
        },
        {
          id:1,
          name:'图片'
        },
        {
          id:2,
          name:'视频'
        }
      ]
    }
  },
  created () {
    this.user_id = JSON.parse(localStorage.getItem('user_id'))
    //判断是否是管理员
    if(this.user_id == 0){
        this._brandList()
    }else{
        this.menData = JSON.parse(localStorage.getItem('store_list'))
        this.formInline.ping = JSON.parse(localStorage.getItem('brand_id'))
        if(this.menData.length>0){
            this.formInline.company_id =  this.menData[0].id
             this.ping =  this.menData[0].id
        }
        this._getPremiseList()
    }
   
  },
  methods: {
    pingClick(val){
       this._storeList(val) 
    },
    menClick(val){
        this.formInline.men =  val
        this._getPremiseList()
    },
    zhuangClick(val){
        this.formInline.zhuang = val
        this._getPremiseList()
    },
    //获取品牌
    _brandList(){
        brandList().then(Er =>{
            if(Er.data && Er.data.length>0){
                this.pinglist = Er.data
                this._storeList(Er.data[0].id)
                // this.formInline.ping = Er.data[0].id
                this.ping = Er.data[0].id
            }
        })
    },
    //获取门店
    _storeList(row){
        storeList({
            brand_id:row
        }).then(Er =>{
            if(Er.return_code == 200){
                if(Er.data && Er.data.length>0){
                    this.menData  = Er.data
                    // this.formInline.company_id =  Er.data[0].id
                }else{
                    this.menData  = []
                }
                this._getPremiseList()
            }
        })
    },
    //获取门店活动
    _getPremiseList () {
       let url = {
         page: this.page,
         brand_id:this.formInline.ping,
         activity_id: 4,
         status: this.formInline.zhuang,
         store_id:this.formInline.company_id
       }
        if(this.type != 1){
        url['company_id'] = this.formInline.company_id
      }
      getPremiseList(url).then(er => {
        if(er.data.data && er.data.data.length>0){
          this.total = er.data.total
          let attr = er.data.data
          attr.map((ec,index) =>{
            ec.index = index+1
            ec.showBtn = false
          })
          this.tableData = attr
        }else{
          this.tableData = []
        }
      })
    },
    // 点击当前页
    sizeChange(val){
      this.page = val
      this._getPremiseList()
    },
    /* 鼠标移入移除 */
    handleMouseEnter(row) {
        row.showBtn = true
    },
    handleMouseLeave(row) {
        row.showBtn = false
    },
    // 编辑
    editClick(val){
      console.log(val)
      this.$router.push({
          path:"/score/scoreAdd",//这个path就是你在router/index.js里边配置的路径
          query:{
                id:val.id,
                company_id:this.ping,
                store_activity_name:val.store_activity_name,
          }
      })
    },
    // 添加小程序广告
    titleaddClick(){
      this.$router.push('/score/scoreAdd?company_id='+this.ping)
    },
    // 查询
    queClick(){
      this.formInline.company_id = ''
      this.formInline.ping = ''
       this._getPremiseList()
    },
    // 启停
    delTeacher(row){
      editStatus({
        brand_id: this.formInline.company_id,
        store_id: this.formInline.men,
        activity_id: 4,
        status: row.status == 0 ? 1 : 0,
        store_activity_id: row.id
      }).then(er => {
        console.log(er)
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this._getPremiseList()
      })


       // saveActivityStatus({
       //      store_activity_id:row.id
       //  }).then(er =>{
       //     if(er.return_code == 200){
       //       this.$message({
       //          message: "修改成功",
       //          type: "success",
       //      });
       //      this._getPremiseList()
       //     }
       //  })


      // console.log(row)
      // let cam = {
      //   company_id:row.company_id,
      //   company_activity_id:row.id,
      //   activity_id:row.activity_id,
      //   is_more:1,
      //   status:row.status == 0 ? 1 : 0
      // }
      // editCompanyActivityStatus(cam).then(er =>{
      //   if(er.return_code == 200){
      //     // this._appletAdList()
      //      this.page = 1
      //     this._getPremiseList()
      //     this.$message({
      //       message: row.status == 0 ? '启用成功' : '禁用成功',
      //       type: 'success'
      //     });
      //   }
      // })
    },
    //查看视频
    chaClick(){
        this.voShow = true
    },
    guan(){
        this.voShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.envelope {
  .describe{
    .describe-title{
      height: 40px;
      line-height: 40px;
      font-weight: bold;
    }
    .describe-cont{
      color: #909399;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .cha{
      text-decoration: underline;
      color: #409EFF;
      margin-top: 10px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .title {
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    >div{
      &:nth-child(1){
        margin-right: 10px;
      }
    }
  }
  .pageStype{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
.tableColumn-control {
    i {
        color: #487ff6;
        cursor: pointer;
    }
    span {
        display: inline-block;
        cursor: pointer;
    }
    div {
        text-align: center;
        background: #D0E9FF;
        position: absolute;
        z-index: 999;
        right: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 0px 0px 0px 12px;
        margin-top: -25px;
        >span{
            margin-right: 12px;
            &:nth-child(1){
                color: #4A80F6;
                a{
                    color: #4A80F6;
                }
            }
        }
    }
}
/deep/ .el-table__row{
    position: relative !important;
    >td{
        position: inherit !important;
        height: 50px;
    }
}
 .from{
    padding: 15px 0px;
  }
  .caoZUO{
      >span{
          color: #409EFF;
          &:nth-child(1){
              margin-right: 10px;
          }
          &:hover{
              cursor: pointer;
          }
      }
  }
  .vCstyle{
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
     
      >div{
           width: 70%;
           height: 70%;
            >video{
                width: 100%;
                height: 70%;
                margin-bottom: 20px;
            }
           >div{
               color: #fff;
               margin: 0 auto;
               width: 300px;
               background: #409EFF;
               text-align: center;
               height: 50px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 5px;
                >i{
                    margin-left: 3px;
                    margin-top: 2px;
            }
              &:hover{
                    cursor: pointer;
                }
           }
      
      }
  }
</style>